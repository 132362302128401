'use strict';

import $ from 'jquery';

const $document = $(document);
const $body = $('body');

// function collapseSearch() {

    var $collapseTrigger = $('.js-search-trigger');
    var $form = $('.js-search-form');

    // register clicks and toggle classes
    $collapseTrigger.on('click', function (e) {

        e.preventDefault();

        var $container = $(this).closest('.js-search-container');
        var $inputEl = $container.find('.js-search-input');
        // var $searchIcon = $('.js-search-icon');

        if ($form.hasClass('active')) {
            $form.removeClass('active');
            $body.removeClass('search-active');
            $('.js-search-container').removeClass('active');
        } else {
            $inputEl.addClass('focus');
            $form.addClass('active');
            $body.addClass('search-active');
            $('.js-search-container').addClass('active');
            // var $labelId = $form.find('.js-search-focus').attr('id');
            $('.js-search-focus').focus();
        }

    });

    $document.on('click', '.search__close, .overlay', function (event) {

        $body.removeClass('search-active');
        $form.removeClass('active');
        $('.js-search-container').removeClass('active');
        // $collapseTrigger.find('i').toggleClass('icon--clear icon--search');

    });

    $document.keyup( function (e) {

        if (e.keyCode === 27) {
            if ($form.hasClass('active')) {
                $form.removeClass('active');
                $body.removeClass('search-active');
                $('.js-search-container').removeClass('active');
                // $collapseTrigger.find('i').toggleClass('icon--clear icon--search');
            }
        }
    });

// }