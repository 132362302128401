// This is made for the hero component
'use strict';

import $ from 'jquery';

$('.js-hero-image').on('load', function (e) {
    const $hero = $(this).closest('.js-image-wrapper');
    const imgSrc = this.currentSrc || this.src;
    $hero.css('background-image', 'url(' + imgSrc + ')');
    setTimeout(function () {
        $hero.addClass('is-loaded');
    }, 250);
}).trigger('load');